import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmmodalComponent } from "../pages/modal/confirmmodal/confirmmodal.component";
import { FiltersPipe } from './filters.pipe';
import { SortPipe } from './sort.pipe'
@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        ConfirmmodalComponent,
        FiltersPipe,
        SortPipe
    ],
    exports: [
        CommonModule,
        ConfirmmodalComponent,
        FiltersPipe,
        SortPipe
    ],
    entryComponents: [
        ConfirmmodalComponent,
    ]
})

export class SharedModule {

}
