import { Component, OnInit } from '@angular/core';
import { CommucateService } from '../../services/commucate.service';
import { OrderService } from "../../services/order.service";
import { UserService } from '../../services/user.service';
import { OrderByPipe } from '../../utils/order-by.pipe';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.css'],
    providers: [OrderByPipe]
})
export class SidebarComponent implements OnInit {
    sidebar_data = [];

    subscription;
    allCount = 0;
    count: any[] = [];
    isCollapsed: boolean;
    constructor(
        private orderService: OrderService,
        private commucateService: CommucateService,
        private userService: UserService,
        private orderBy: OrderByPipe,
    ) {
        this.subscription = this.commucateService.zoneObservable.subscribe(
            data => {
                let res: any = data;
                this.allCount = 0;
                this.count = res;
                this.makeBar(this.count, this.sidebar_data);
            }
        );
        this.commucateService.zonesObservable.subscribe(
            data => {
                this.sidebar_data = [];
                let newdata: any = data;
                newdata.forEach(item => {
                    let zone = {
                        name: item.name,
                        id: item.id,
                        count: 0
                    }
                    this.sidebar_data.push(zone);
                });
                this.count.forEach(item => {
                    this.sidebar_data.forEach(bar => {
                        if (item.zone_id == bar.id) {
                            bar["count"] = item.count;
                        }
                    })
                });
            }
        );
    }
    isAdmin = this.userService.isAdmin();
    isDispatch = this.userService.isDispatch();
    isClient = this.userService.isClient();
    ngOnInit() {
        if (this.isAdmin || this.isDispatch) {
            this.getZone();
        } else {
            this.initClientSidebar();
        }
    }

    makeBar(count, bar: any[]) {
        count.forEach(item => {
            this.allCount = this.allCount + item.count;
            bar.forEach(bar => {
                if (item.zone_id == bar.id) {
                    bar["count"] = item.count;
                }
            })
        });
    }
    getZone() {
        this.orderService.getZones().subscribe(
            data => {
                this.sidebar_data = [];
                let newdata = data.results;
                for (let item of newdata) {
                    let zone = {
                        name: item.name,
                        id: item.id,
                        count: 0
                    }
                    this.sidebar_data.push(zone);
                    this.sidebar_data.forEach(v => {
                        v['sort_name'] = v.name.toLowerCase();
                    });
                    this.sidebar_data = this.orderBy.transform(this.sidebar_data, 'sort_name', 'asc')
                }
            },
            err => {

            }
        )
    }
    initClientSidebar() {
        let client_id = this.userService.getLocalCurrentUser().client_id;
        this.orderService.getCostCenter(client_id).subscribe(
            data => {
                for (let item of data.results) {
                    let coset_center = {
                        name: item.name,
                        id: item.id,
                        count: 0
                    }
                    this.sidebar_data.push(coset_center);
                    this.sidebar_data.forEach(v => {
                        v['sort_name'] = v.name.toLowerCase();
                    });
                    this.sidebar_data = this.orderBy.transform(this.sidebar_data, 'sort_name', 'asc')
                }
            },
            err => {

            }
        );
    }

}
