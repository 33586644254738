import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
    selector: 'app-confirmmodal',
    templateUrl: './confirmmodal.component.html',
    styleUrls: ['./confirmmodal.component.css']
})
export class ConfirmmodalComponent implements OnInit {
    @Input() actionData: any;
    @Input() actionType: any;
    isReturn: boolean = false;
    returnButton = "";
    constructor(
        public activeModal: NgbActiveModal
    ) {

    }

    ngOnInit() {
        if (this.actionType == "return the order to queue") {
            this.isReturn = true;
            this.returnButton = "Do it";
        } else if(this.actionType == "add 10 mins wait time"){
            this.returnButton = "Add";
        } else {
            this.returnButton = this.actionType;
        }
    }

}
