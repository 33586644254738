import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

    constructor(
        private router: Router,
        private userService: UserService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        let isLoggedIn = false; // ... your login logic here
        if (this.userService.isAdmin() || this.userService.isDispatch() || this.userService.isClient()) {
            isLoggedIn = true;
        }
        if (isLoggedIn) {
            return true;
        } else {
            this.router.navigate(['login']);
            return false;
        }
    }
}
