import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators/map';
import { URL_CONSTANT } from "../constants/urlConstants";
import { ApiService } from './api.service';
import { AuthTokenService } from './authToken.service';

const userGroup = {
    "ADMIN": 2,
    "DISPATCH": 3,
    "COURIER": 4,
    "CLIENT": 5
}
@Injectable({
    providedIn: 'root'
})
export class UserService {
    private currentUser: any;
    public refreshed: boolean = false;
    constructor(
        private apiService: ApiService,
        private authTokenService: AuthTokenService
    ) {}

    getUserById(id): Observable<any> {
        return this.apiService.get(URL_CONSTANT.BASE_URL + URL_CONSTANT.USERS + id).pipe(map(
            data => {
                return data;
            }
        ));
    }

    getCouriers(f): Observable<any> {
        let urlParams = "?groups=4";
        f.forEach(item => {
            urlParams += "&" + item[0] + "=" + item[1];
        });
        return this.apiService.get(URL_CONSTANT.BASE_URL + URL_CONSTANT.USERS + urlParams).pipe(map(
            data => {
                return data;
            }
        ));
    }

    getPartners(f): Observable<any> {
        let urlParams = "?groups=6";
        f.forEach(item => {
            urlParams += "&" + item[0] + "=" + item[1];
        });
        return this.apiService.get(URL_CONSTANT.BASE_URL + URL_CONSTANT.USERS + urlParams).pipe(map(
            data => {
                return data;
            }
        ));
    }

    sendEmail(email): Observable<any> {
        let payload = {
            "email": email
        }
        return this.apiService.post(URL_CONSTANT.BASE_URL + URL_CONSTANT.USERS + URL_CONSTANT.GET_RESET, payload).pipe(map(
            data => {
                return data;
            }
        ));
    }
    resetPassword(email, pw, repw, token, type): Observable<any> {
        const payload = {
            "email": email,
            "reset_token": token,
            "password": pw,
            "password_confirmation": repw,
            "type": type
        }
        return this.apiService.post(URL_CONSTANT.BASE_URL + URL_CONSTANT.USERS + URL_CONSTANT.RESET_PASSWORD, payload).pipe(map(
            data => {
                return data;
            }
        ));
    }
    login(email, password): Observable<any> {
        let formData = new FormData();
        formData.append("username", email);
        formData.append("password", password);
        formData.append("grant_type", "password");
        formData.append("client_id", "1QOCTIlUrESYWpDNf9keR6OwAehoAzd5ISgwvglh");
        formData.append("client_secret", "jQOb5WCV2betjVZYuDlqSYKrvmwF03dTCrE4NwQYXF8bHmzpkLCWfS8lxefbBE6zVOOOQbS9grHBCShkPX3oXzLgWdXnDA59kOmi1BcFpiDtOLOx3guQ7Ry966R5nv9q");
        return this.apiService.fdPost(URL_CONSTANT.BASE_URL + URL_CONSTANT.LOGIN, formData).pipe(map(
            data => {
                if (data.access_token) {
                    this.authTokenService.saveAuth(data);
                }
                return data;
            }
        ));
    }
    getCurrentUser(): Observable<any> {
        return this.apiService.get(URL_CONSTANT.BASE_URL + URL_CONSTANT.CURRENT_USER).pipe(map(
            data => {
                this.currentUser = data;
                localStorage.setItem("currentUser", JSON.stringify(this.currentUser));
                return data;
            })
        );
    }
    getLocalCurrentUser() {
        let current_user: any = JSON.parse(localStorage.getItem('currentUser'));
        if (current_user) {
            this.currentUser = current_user;
        }
        return current_user;
    }

    refreshLogin(token) {
        let formData = new FormData();
        formData.append("refresh_token", token);
        formData.append("grant_type", "refresh_token");
        formData.append("client_id", "1QOCTIlUrESYWpDNf9keR6OwAehoAzd5ISgwvglh");
        formData.append("client_secret", "jQOb5WCV2betjVZYuDlqSYKrvmwF03dTCrE4NwQYXF8bHmzpkLCWfS8lxefbBE6zVOOOQbS9grHBCShkPX3oXzLgWdXnDA59kOmi1BcFpiDtOLOx3guQ7Ry966R5nv9q");
        return this.apiService.fdPost(URL_CONSTANT.BASE_URL + URL_CONSTANT.LOGIN, formData).pipe(map(
            data => {
                if (data.access_token) {
                    this.authTokenService.saveAuth(data);
                }
                return data;
            }
        ));
    }
    doLogout() {
        this.authTokenService.destroyAuth();
        this.currentUser = null;
        localStorage.removeItem('currentUser');
    }

    createCourier(courier): Observable<any> {
        return this.apiService.post(URL_CONSTANT.BASE_URL + URL_CONSTANT.USERS, courier).pipe(map(
            data => {
                return data;
            }
        ));
    }
    updateCourier(courier, id): Observable<any> {
        return this.apiService.put(URL_CONSTANT.BASE_URL + URL_CONSTANT.USERS + id + "/", courier).pipe(map(
            data => {
                return data;
            }
        ));
    }
    deteleCourier(id): Observable<any> {
        return this.apiService.delete(URL_CONSTANT.BASE_URL + URL_CONSTANT.USERS + id).pipe(map(
            data => {
                return data;
            }
        ));
    }
    deteleCourierMuti(ids): Observable<any> {
        return this.apiService.deleteMult(URL_CONSTANT.BASE_URL + URL_CONSTANT.USERS + URL_CONSTANT.ACTION_BATCH, ids).pipe(map(
            data => {
                return data;
            }
        ));
    }

    isLogin() {
        return this.getLocalCurrentUser() != null;
    }

    isAdmin() {
        if (this.isLogin()) {
            return this.getLocalCurrentUser().groups[0].id == userGroup.ADMIN;
        } else {
            return false;
        }
    }

    isDispatch() {
        if (this.isLogin()) {
            return this.getLocalCurrentUser().groups[0].id == userGroup.DISPATCH;
        } else {
            return false;
        }
    }

    isClient() {
        if (this.isLogin()) {
            return this.getLocalCurrentUser().groups[0].id == userGroup.CLIENT;
        } else {
            return false;
        }
    }

    isCourier() {
        if (this.isLogin()) {
            return this.getLocalCurrentUser().groups[0].id == userGroup.COURIER;
        } else {
            return false;
        }
    }
    getActiveZoneUsers(search): Observable<any> {
        let urlParams = "?search=" + search;
        urlParams += `&ordering=`;
        let orderBy = [['', 'last_name'], ['', 'first_name']];
        orderBy.forEach((orderby, index) => {
            urlParams += `${index == 0 ? `` : `,`}${orderby[0]}${orderby[1]}`;
        });
        return this.apiService.get(URL_CONSTANT.BASE_URL + URL_CONSTANT.USERS + URL_CONSTANT.ACTIVE_ZONE_USER + urlParams).pipe(map(
            data => {
                return data;
            }
        ));
    }

    getGpsPosition(): Observable<any> {
        return this.apiService.get(URL_CONSTANT.BASE_URL + URL_CONSTANT.GPS_POSITION).pipe(map(
            data => {
                return data;
            }
        ));
    }

    getLogs(id): Observable<any> {
        return this.apiService.get(URL_CONSTANT.BASE_URL + URL_CONSTANT.LOGS_URL + '?user_id=' + id).pipe(map(
            data => {
                return data;
            }
        ));
    }

    getLogsCounts(id): Observable<any> {
        return this.apiService.get(URL_CONSTANT.BASE_URL + URL_CONSTANT.PARTNER_LOGS_COUNT + id).pipe(map(
            data => {
                return data;
            }
        ));
    }

    getCreationCounts(id): Observable<any> {
        return this.apiService.get(URL_CONSTANT.BASE_URL + URL_CONSTANT.PARTNER_CREATE_COUNTS + id).pipe(map(
            data => {
                return data;
            }
        ));
    }
}
