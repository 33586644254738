import { Injectable } from '@angular/core';

@Injectable()
export class AuthTokenService {
    private accessToken;
    private refreshToken;

    saveAuth(auth) {
        localStorage.setItem("access_token", auth.access_token);
        localStorage.setItem("refresh_token", auth.refresh_token);
    }
    getAuth() {
        return localStorage.getItem("access_token");
    }
    refreshAuth() {
        return localStorage.getItem("refresh_token");
    }
    destroyAuth() {
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        this.accessToken = null;
        this.refreshToken = null;
    }

}
