import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";
import { map } from "rxjs/operators/map";
import { URL_CONSTANT } from "../constants/urlConstants";
import { ApiService } from "./api.service";

@Injectable({
    providedIn: "root"
})
export class OrderService {
    private url = URL_CONSTANT;

    constructor(
        private apiService: ApiService
    ) { }

    creatOrder(item): Observable<any> {
        let formData = new FormData();
        for (let key in item) {
            if (item[key] != null) {
                if (Array.isArray(item[key])) {
                    item[key].forEach(v => {
                        formData.append(`${key}[]`, v);
                    });
                } else {
                    formData.append(key, item[key]);
                }
            } else {
                formData.append(key, "");
            };
        }
        return this.apiService.fdPost(this.url.BASE_URL + this.url.ORDERS, formData).pipe(map(
            data => {
                return data;
            }
        ));
    }
    updateOrder(item, id): Observable<any> {
        return this.apiService.put(this.url.BASE_URL + this.url.ORDERS + id + "/", item).pipe(map(
            data => {
                return data;
            }
        ));
    }
    getOrder(field, order, filter): Observable<any> {
        let urlParams = "?";
        if ((typeof field !== "undefined") && field != null) {
            if (field == "delvr_time" || field == "burn") {
                if (order == "asc") {
                    urlParams += "ordering=day,time";
                } else if (order == "desc") {
                    urlParams += "ordering=-day,-time";
                }
            } else {
                if (order == "asc") {
                    urlParams += "ordering=" + field;
                } else if (order == "desc") {
                    urlParams += "ordering=-" + field;
                }
            }
        }
        filter.forEach(item => {
            urlParams += "&" + item[0] + "=" + item[1];
        });
        return this.apiService.get(this.url.BASE_URL + this.url.ORDERS + urlParams).pipe(map(
            data => {
                return data;
            }
        ));
    }

    getFinishOrder(field, order, filter): Observable<any> {
        let urlParams = "?";
        if ((typeof field !== "undefined") && field != null) {
            if (field == "delvr_time" || field == "burn") {
                if (order == "asc") {
                    urlParams += "ordering=day,time";
                } else if (order == "desc") {
                    urlParams += "ordering=-day,-time";
                }
            } else {
                if (order == "asc") {
                    urlParams += "ordering=" + field;
                } else if (order == "desc") {
                    urlParams += "ordering=-" + field;
                }
            }
        }
        filter.forEach(item => {
            urlParams += "&" + item[0] + "=" + item[1];
        });
        return this.apiService.get(this.url.BASE_URL + this.url.ORDERS + this.url.FINISH_ORDER + urlParams).pipe(map(
            data => {
                return data;
            }
        ));
    }

    getOrderById(id): Observable<any> {
        return this.apiService.get(this.url.BASE_URL + this.url.ORDERS + id + "/").pipe(map(
            data => {
                return data;
            }
        ));
    }

    getOrderLogs(id): Observable<any> {
        let urlParams = "?";
        if ((typeof id !== "undefined") && id != null) {
            urlParams += "order_id=" + id;
        }
        return this.apiService.get(this.url.BASE_URL + this.url.ORDER_LOGS + urlParams).pipe(map(
            data => {
                return data;
            }
        ));
    }

    postDispatch(item, id): Observable<any> {
        return this.apiService.put(this.url.BASE_URL + this.url.ORDERS + id + "/", item).pipe(map(
            data => {
                return data;
            }
        ));
    }

    fetchAddress(client, cost_center): Observable<any> {
        let data = {
            client_id: client,
            cost_center_id: cost_center
        }
        return this.apiService.post(this.url.BASE_URL + this.url.ADDRESSES + this.url.FETCH_ADDRESS, data).pipe(map(
            data => {
                return data;
            }
        ));
    }

    createOrderFile(id, file): Observable<any> {
        let data = {
            file: file,
            order_id: id
        }
        let formData = new FormData();
        for (let key in data) {
            if (data[key] != null) {
                formData.append(key, data[key]);
            }
        }
        return this.apiService.fdPost(this.url.BASE_URL + this.url.FILE, formData).pipe(map(
            data => {
                return data;
            }
        ));
    }

    deleteOrderFile(id): Observable<any> {
        return this.apiService.delete(this.url.BASE_URL + this.url.FILE + id + "/").pipe(map(
            data => {
                return data;
            }
        ));
    }

    reorder(id): Observable<any> {
        let data = {
            order_id: id
        }
        return this.apiService.post(this.url.BASE_URL + this.url.ORDERS + this.url.REORDER, data).pipe(map(
            data => {
                return data;
            }
        ));
    }

    /* ----------------------------------------------
    Zones
    ---------------------------------------------- */
    getZones(): Observable<any> {
        return this.apiService.get(this.url.BASE_URL + this.url.ZONES).pipe(map(
            data => {
                return data;
            }
        ));
    }

    /* ----------------------------------------------
    Clients
    ---------------------------------------------- */
    getClient(search): Observable<any> {
        let urlParams = "?";
        if ((typeof search !== "undefined") && search != null) {
            urlParams += "search=" + search;
        }
        return this.apiService.get(this.url.BASE_URL + this.url.CLIENTS + urlParams).pipe(map(
            data => {
                return data;
            }
        ));
    }

    getClientById(id): Observable<any> {
        return this.apiService.get(this.url.BASE_URL + this.url.CLIENTS + id + "/").pipe(map(
            data => {
                return data;
            }
        ));
    }

    getAllClient(filter): Observable<any> {
        let urlParams = "?"
        filter.forEach(item => {
            urlParams += "&" + item[0] + "=" + item[1];
        });
        return this.apiService.get(this.url.BASE_URL + this.url.CLIENTS + urlParams).pipe(map(
            data => {
                return data;
            }
        ));
    }

    createClient(urlParams) {
        return this.apiService.post(this.url.BASE_URL + this.url.CLIENTS, urlParams).pipe(map(
            data => {
                return data;
            }
        ));
    }

    updateClient(id, urlParams) {
        return this.apiService.put(this.url.BASE_URL + this.url.ADDRESSES + id + "/", urlParams).pipe(map(
            data => {
                return data;
            }
        ));
    }

    updateClientById(id, urlParams) {
        return this.apiService.put(this.url.BASE_URL + this.url.CLIENTS + id + "/", urlParams).pipe(map(
            data => {
                return data;
            }
        ));
    }

    deleteCostCenter(data) {
        return this.apiService.deleteMult(this.url.BASE_URL + this.url.COST_CENTER + this.url.ACTION_BATCH, data).pipe(map(
            data => {
                return data;
            }
        ));
    }

    getCostCenter(clientId): Observable<any> {
        let urlParams = "?";
        if ((typeof clientId !== "undefined") && clientId != null) {
            urlParams += "client_id=" + clientId;
        }
        return this.apiService.get(this.url.BASE_URL + this.url.COST_CENTER + urlParams).pipe(map(
            data => {
                return data;
            }
        ));
    }

    getCostCenterById(id): Observable<any> {
        return this.apiService.get(this.url.BASE_URL + this.url.COST_CENTER + id + "/").pipe(map(
            data => {
                return data;
            }
        ));
    }

    updateOrderFile(id,item): Observable<any> {
        return this.apiService.put(this.url.BASE_URL + this.url.FILE + id + "/", item).pipe(map(
            data => {
                return data;
            }
        ));
    }
}
