import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ApiService {
    constructor(
        private http: HttpClient
    ) { }

    private formatErrors(error: any) {
        return throwError(error.error);
    }

    get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
        return this.http.get(`${path}`, { params })
            .pipe(catchError(this.formatErrors));
    }

    put(path: string, body: object = {}): Observable<any> {
        return this.http.put(
            `${path}`,
            JSON.stringify(body)
        ).pipe(catchError(this.formatErrors));
    }

    post(path: string, body: object = {}): Observable<any> {
        return this.http.post(
            `${path}`,
            JSON.stringify(body)
        ).pipe(catchError(this.formatErrors));
    }

    delete(path): Observable<any> {
        return this.http.delete(
            `${path}`
        ).pipe(catchError(this.formatErrors));
    }

    deleteMult(path: string, body: object): Observable<any> {
        return this.http.request("DELETE", `${path}`, { body }
        ).pipe(catchError(this.formatErrors));
    }

    fdPost(path: string, body: FormData): Observable<any> {
        return this.http.post(
            `${path}`, body
        ).pipe(catchError(this.formatErrors));
    }

    fdPut(path: string, body: FormData): Observable<any> {
        return this.http.put(
            `${path}`, body
        ).pipe(catchError(this.formatErrors));
    }

}