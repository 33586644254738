import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Ng2CsvModule } from 'ng2csv';
import { ToastrModule } from 'ngx-toastr';
import { AppComponent } from './app.component';
import { routes } from './app.routes';
import { HomeComponent } from './pages/home/home.component';
import { SettingComponent } from "./pages/setting/setting.component";
import { ForgetPwdComponent } from './pages/user/forget-pwd/forget-pwd.component';
import { LoginComponent } from './pages/user/login/login.component';
import { ApiService } from './services/api.service';
import { AuthTokenService } from './services/authToken.service';
import { HttpRequestInterceptor } from "./services/httpRequest.interceptor";
import { HeaderComponent } from './utils/header/header.component';
import { SharedModule } from './utils/shared.module';
import { SidebarComponent } from './utils/sidebar/sidebar.component';
import { PendingChangesGuard } from './utils/can-be-deactived';
import { WebSocketService } from './services/web-socket.service';

@NgModule({
    declarations: [
        AppComponent,
        SettingComponent,
        HeaderComponent,
        SidebarComponent,
        LoginComponent,
        HomeComponent,
        ForgetPwdComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(routes),
        NgbModule.forRoot(),
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        ToastrModule.forRoot({
            timeOut: 15000,
            closeButton: true,
            progressBar: true
        }),
        Ng2CsvModule
    ],
    providers: [
        ApiService,
        AuthTokenService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpRequestInterceptor,
            multi: true
        },
        PendingChangesGuard,
        WebSocketService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
