import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
@Injectable({
    providedIn: 'root'
})
export class CommucateService {
    private zoneSource = new Subject();
    private orderSource = new Subject();
    private courierSource = new Subject();
    private zonesSource = new Subject();
    dashboard_orders: any[];
    future_orders: any[];
    latest_time;
    active_couriers: any[];
    //获得一个Observable
    zoneObservable = this.zoneSource.asObservable();
    orderObservable = this.orderSource.asObservable();
    courierObservable = this.courierSource.asObservable();
    zonesObservable = this.zonesSource.asObservable();
    constructor() { }
    //发射数据，当调用这个方法的时候，Subject就会发射这个数据，所有订阅了这个Subject的Subscription都会接受到结果
    sendMessage(item: any[]) {
        this.zoneSource.next(item);
    }
    orderMessage(orders: any[]) {
        this.orderSource.next(orders);
    }
    courierMessage(couriers){
        this.courierSource.next(couriers);
    }
    zonesMessage(zone: any[]) {
        this.zonesSource.next(zone);
    }
}
