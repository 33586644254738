import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

    constructor(
        private router: Router,
        private userService: UserService
    ) {

    }

    isAdmin = this.userService.isAdmin();
    isDispatch = this.userService.isDispatch();
    isClient = this.userService.isClient();

    ngOnInit() {
    }

    doLogout() {
        this.userService.doLogout();
        this.router.navigateByUrl("");
    }
}
