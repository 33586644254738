import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filters'
})
export class FiltersPipe implements PipeTransform {

	transform(items: any, filter: any): any {
		if (filter && Array.isArray(items)) {
			let temp_array = [];
			for(let key in filter){
				if(!filter[key]){
					filter[key] = '';
				};
				temp_array = items.filter(item=>(item[key].toLocaleLowerCase().indexOf(filter[key].toLocaleLowerCase()) != -1));
			};
			return temp_array;
		} else {
			return items;
		}
	}

}
