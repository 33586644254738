import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators/map';
import { URL_CONSTANT } from "../constants/urlConstants";
import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root'
})
export class ConfigService {
    private url = URL_CONSTANT;

    constructor(
        private apiService: ApiService
    ) { }

    getConfigs(type): Observable<any> {
        let item = "?group=" + type
        return this.apiService.get(this.url.BASE_URL + this.url.CONFIGS + item).pipe(map(
            data => {
                return data;
            }
        ));
    }

    updateOrderTime(id, value): Observable<any> {
        let item = {
            value: value
        }
        return this.apiService.put(this.url.BASE_URL + this.url.CONFIGS + id + "/", item).pipe(map(
            data => {
                return data;
            }
        ));
    }
    saveTiersBatch(value): Observable<any> {
        let item = value;
        return this.apiService.post(this.url.BASE_URL + this.url.CONFIGS + this.url.ACTION_BATCH, item).pipe(map(
            data => {
                return data;
            }
        ));
    }
    saveZonesBatch(value): Observable<any> {
        let item = value;
        return this.apiService.post(this.url.BASE_URL + this.url.ZONES + this.url.ACTION_BATCH, item).pipe(map(
            data => {
                return data;
            }
        ));
    }

}
