import { Component, OnInit } from '@angular/core';
import { NgbAccordionConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CommucateService } from '../../services/commucate.service';
import { ConfigService } from '../../services/config.service';
import { OrderService } from "../../services/order.service";
import { UserService } from 'src/app/services/user.service';
import { WebSocketService } from '../../services/web-socket.service';
@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css'],
    providers: [NgbAccordionConfig]
})
export class HomeComponent implements OnInit {

    constructor(
        public config: NgbAccordionConfig,
        private orderService: OrderService,
        private commucateService: CommucateService,
        public toastr: ToastrService,
        private configService: ConfigService,
        private userService: UserService,
        private wsService:WebSocketService
    ) { }

    ngOnInit() {
        this.getOrders();
        this.initOrderTime();
        setInterval(() => {
            this.initOrderTime();
        }, 10000);
        setInterval(() => {
            this.getCouriers();
        }, 5000);
        // this.wsService.createObservableSocket("ws://192.168.1.171:8000/chart/push")
        //     .subscribe(
        //       data => {console.log(data)},
        //       err => console.log(err),
        //       () => console.log("流已经结束")
        //     );
    }
    zone_list: any[];
    timeWindow = 3;
    requesting = false;
    get_config = false;
    requesting_fail = 0;
    config_fail = 0;
    countList(array) {
        this.orderService.getZones().subscribe(
            data => {
                let zoneCount = [];
                for (let item in data.results) {
                    let obj = {
                        zone_id: data.results[item]['id'],
                        count: 0
                    }
                    for (let order in array) {
                        let zone_map = array[order]['zone'].map(v=>v.id);
                        if(zone_map.includes(obj.zone_id)){
                        // if (obj.zone_id == array[order]['zone']) {
                            obj.count++;
                        }
                    }
                    zoneCount.push(obj);
                }
                this.commucateService.sendMessage(zoneCount);
            },
            err => {
                // this.toastr.error(err);
            }
        )
    }
    getOrders = () => {
        this.orderService.getOrder('updated_at', 'desc', [['active', 1]])
            .subscribe(
                data => {
                    if (!data.results[0]) {
                        this.commucateService.latest_time = new Date().toISOString();
                    } else {
                        this.commucateService.latest_time = data.results[0].updated_at;
                    }
                    this.commucateService.dashboard_orders = [];
                    this.commucateService.future_orders = [];
                    data.results.forEach(ele => {
                        if (this.classifyOrders(ele)) {
                            this.commucateService.dashboard_orders.push(ele);
                        } else {
                            this.commucateService.future_orders.push(ele);
                        }
                    })
                    this.commucateService.orderMessage(data);
                    this.countList(this.commucateService.dashboard_orders);
                    setInterval(() => {
                        this.getNewOrder();
                    }, 2700);
                    setInterval(() => {
                        this.refreshOrder();
                    }, 3300);
                },
                err => {
                    // this.toastr.error(JSON.stringify(err))
                }
            );
    }

    getNewOrder = () => {
        if (this.requesting) {
            this.requesting_fail++;
            if (this.requesting_fail > 10) {
                this.requesting_fail = 0;
                this.requesting = false;
            }
            return;
        }
        this.requesting = true;
        this.orderService.getOrder('updated_at', 'desc', [['latest_time', this.commucateService.latest_time]])
            .subscribe(
                data => {
                    this.requesting = false;
                    data.results.forEach(ele => {
                        if ( !ele.status || !ele.delivery_time_type || !ele.delivery_to_address){
                            return;
                        }
                        if (ele.status == 'DELIVERED' || ele.status == 'CANCELED') {
                            if (this.inArray(this.commucateService.future_orders, ele).flag) {
                                this.commucateService.future_orders.splice(this.inArray(this.commucateService.future_orders, ele).index, 1);
                            }
                            if (this.inArray(this.commucateService.dashboard_orders, ele).flag) {
                                this.commucateService.dashboard_orders.splice(this.inArray(this.commucateService.dashboard_orders, ele).index, 1);
                            }
                        } else {
                            if (this.classifyOrders(ele)) {
                                if (this.inArray(this.commucateService.future_orders, ele).flag) {
                                    this.commucateService.future_orders.splice(this.inArray(this.commucateService.future_orders, ele).index, 1);
                                }
                                if (!this.inArray(this.commucateService.dashboard_orders, ele).flag) {
                                    this.commucateService.dashboard_orders.push(ele);
                                } else {
                                    this.commucateService.dashboard_orders.forEach((order, i) => {
                                        if (ele.id == order.id) {
                                            this.commucateService.dashboard_orders[i] = ele;
                                        }
                                    })
                                }
                            } else {
                                if (this.inArray(this.commucateService.dashboard_orders, ele).flag) {
                                    this.commucateService.dashboard_orders.splice(this.inArray(this.commucateService.dashboard_orders, ele).index, 1);
                                }
                                if (!this.inArray(this.commucateService.future_orders, ele).flag) {
                                    this.commucateService.future_orders.push(ele);
                                } else {
                                    this.commucateService.future_orders.forEach((order, i) => {
                                        if (ele.id == order.id) {
                                            this.commucateService.future_orders[i] = ele;
                                        }
                                    })
                                }
                            }
                        }
                    })
                    if (data.results.length > 0) {
                        this.commucateService.latest_time = data.results[0].updated_at;
                        this.commucateService.orderMessage(data);
                        this.countList(this.commucateService.dashboard_orders);
                    }
                },
                err => {
                    this.requesting = false;
                    // this.toastr.error(JSON.stringify(err))
                }
            );
    }
    refreshOrder = () => {
        this.commucateService.future_orders.forEach((order, i) => {
            if (this.classifyOrders(order)) {
                this.commucateService.dashboard_orders.push(order);
                this.commucateService.future_orders.splice(i, 1);
                this.commucateService.orderMessage([]);
                this.countList(this.commucateService.dashboard_orders);
            }
        })
        this.commucateService.dashboard_orders.forEach((order, i) => {
            if (!this.classifyOrders(order)) {
                this.commucateService.future_orders.push(order);
                this.commucateService.dashboard_orders.splice(i, 1);
                this.commucateService.orderMessage([]);
                this.countList(this.commucateService.dashboard_orders);
            }
        })
    }
    initOrderTime() {
        if (this.get_config) {
            this.config_fail++;
            if (this.config_fail > 1) {
                this.config_fail = 0;
                this.get_config = false;
            }
            return;
        }
        this.get_config = true;
        this.configService.getConfigs("ORDER TIME").subscribe(
            data => {
                data.results.forEach(ele => {
                    if (ele.name == "TIME WINDOW") {
                        this.timeWindow = parseInt(ele.value);
                    }
                });
                this.get_config = false;
            },
            err => {
                this.get_config = false;
                // this.toastr.error(JSON.stringify(err))
            }
        );
    }

    classifyOrders(order) {
        let end_time = new Date(new Date().getTime() + this.timeWindow * 3600 * 1000);
        let pick_time = new Date(order.pick_up_time);
        if (pick_time < end_time) {
            return true;
        } else {
            return false;
        }
    }

    inArray(arr, item) {
        let result = {
            'flag': false,
            'index': 0
        }
        arr.forEach((ele, i) => {
            if (ele.id == item.id) {
                result.flag = true;
                result.index = i;
            }
        })
        return result;
    }
    allOnlineCourier;
    getCouriers = () => {
        this.userService.getActiveZoneUsers("").subscribe(
            data => {
                this.allOnlineCourier = data;
                this.commucateService.active_couriers = data;
                this.commucateService.courierMessage(data);
            },
            err => {

            }
        );
    }
}
