import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from "../../../services/user.service";

@Component({
    selector: 'app-forget-pwd',
    templateUrl: './forget-pwd.component.html',
    styleUrls: ['./forget-pwd.component.css']
})
export class ForgetPwdComponent implements OnInit {
    userEmail = "";
    userPassword = "";
    userRePassword = "";
    type = "";
    userToken = "";
    errorMsg: string;
    btnDisable = false;
    emailSent = false;
    isSent = false;
    isReset = false;
    constructor(
        private router: Router,
        public activeRoute: ActivatedRoute,
        private userService: UserService
    ) { }

    ngOnInit() {
        this.activeRoute.queryParams.subscribe(params => {
            if (params.reset_token) {
                this.userToken = params.reset_token;
                this.userEmail = params.email;
                if (params.type) {
                    this.type = params.type;
                } else {
                    this.type = "register";
                }
                this.isSent = true;
            }
        });
    }
    sendEmail() {
        this.btnDisable = true;
        this.emailSent = false;
        this.userService.sendEmail(this.userEmail).subscribe(
            data => {
                this.emailSent = true;
                this.btnDisable = false;
            },
            err => {
                this.btnDisable = false;
                this.errorMsg = "Invalid email, please check your input"
                setTimeout(() => {
                    this.errorMsg = '';
                }, 3000);
            }
        );
    }
    resetPassword() {
        this.errorMsg = null;
        this.btnDisable = true;
        if (this.userPassword && this.userRePassword) {
            if (this.userPassword.length >= 8) {
                if (this.userPassword == this.userRePassword) {
                    this.userService.resetPassword(this.userEmail, this.userPassword, this.userRePassword, this.userToken, this.type).subscribe(
                        data => {
                            this.btnDisable = false;
                            this.isReset = true;
                        },
                        err => {
                            this.btnDisable = false;
                            this.errorMsg = err.message;
                        }
                    );
                } else {
                    this.btnDisable = false;
                    this.errorMsg = "Password and Confirm Password do not match";
                }
            } else {
                this.btnDisable = false;
                this.errorMsg = "Passwords must be at least 8 characters long";
            }
        } else {
            this.btnDisable = false;
            this.errorMsg = "Please fill the required field";
        }
        setTimeout(() => {
            this.errorMsg = '';
        }, 3000);
    }

    goLogin() {
        this.router.navigate(['login']);
    }
}
