import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router'; 
import { Observable } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmmodalComponent } from '../pages/modal/confirmmodal/confirmmodal.component';

export interface ComponentCanDeactivate {
	canDeactivate: () => any | Observable<any>;
}

@Injectable()
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {
	constructor(
		public modalService: NgbModal,
	){}
	canDeactivate(component: ComponentCanDeactivate): any | Observable<any> {
		return component.canDeactivate() ? true : this.confirmModal();
	}
	confirmModal(){
		let contect = "Please save your changes!";
      	let modal = this.modalService.open(ConfirmmodalComponent, { size: "sm", backdropClass: "stacked-modals", "centered": true, backdrop:'static' });
      	modal.componentInstance.actionData = contect;
      	modal.componentInstance.actionType = 'Save';
      	return modal.result.then(res=>{
      		return false;
      	}, rej=>{
      		return true;
      	});
	}
}
