import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import 'rxjs/add/operator/do';
import { Observable } from 'rxjs/Observable';
import { URL_CONSTANT } from "../constants/urlConstants";
import { AuthTokenService } from './authToken.service';
import { UserService } from './user.service';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
    refreshed = false;
    constructor(
        private authTokenService: AuthTokenService,
        private router: Router,
        private userService: UserService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let headersConfig = {
            'Accept': 'application/json'
        };

        let token = this.authTokenService.getAuth();

        if (token) {
            headersConfig['Authorization'] = `Bearer ${token}`;
        }

        if (!(req.url.includes(URL_CONSTANT.LOGIN) || (req.method == "POST" && req.url.includes(URL_CONSTANT.ORDERS)) || (req.method == "POST" && req.url.includes(URL_CONSTANT.FILE)))) {
            headersConfig['Content-Type'] = 'application/json';
        }
        if (req.url.includes(URL_CONSTANT.REORDER)) {
            headersConfig['Content-Type'] = 'application/json';
        }

        const request = req.clone({ setHeaders: headersConfig });
        return next.handle(request).do(
            (event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    // do stuff with response if you want
                }
            }, (err: any) => {
                if (err instanceof HttpErrorResponse) {
                    if (!!err.error.detail && !err.error.message) {
                        err.error.message = err.error.detail;
                    }
                    if (err.status === 401 && !this.refreshed) {
                        if(token != this.authTokenService.getAuth()){
                            return;
                        }
                        let refresh_token = this.authTokenService.refreshAuth();
                        if (refresh_token) {
                            this.refreshed = true;
                            this.userService.refreshLogin(refresh_token).subscribe(
                                data => {
                                    this.refreshed = false;
                                    this.authTokenService.saveAuth(data);
                                    this.userService.getCurrentUser().subscribe(
                                        data => {

                                        },
                                        err => {

                                        }
                                    );
                                },
                                err => {
                                    this.refreshed = false;
                                    this.userService.doLogout();
                                    this.router.navigate(['login']);
                                }
                            );
                        } else {
                            this.userService.doLogout();
                            this.router.navigate(['login']);
                        }
                    }
                }
            });
    }

}