import { Routes } from "@angular/router";
import { HomeComponent } from "./pages/home/home.component";
import { SettingComponent } from "./pages/setting/setting.component";
import { ForgetPwdComponent } from "./pages/user/forget-pwd/forget-pwd.component";
import { LoginComponent } from "./pages/user/login/login.component";
import { AuthAdminGuardService } from "./services/authAdminGuard.service";
import { AuthClientGuardService } from "./services/authClientGuard.service";
import { AuthGuardService } from "./services/authGuard.service";
import { PendingChangesGuard } from './utils/can-be-deactived';
export const routes: Routes = [
    {
        path: "",
        component: HomeComponent,
        canActivate: [AuthGuardService],
        children: [
            { path: "", redirectTo: "order", pathMatch: "full" },
            { path: "order", loadChildren: "./pages/order/order.module#OrderModule" },
            {
                path: "manage-client",
                loadChildren: "./pages/manage-client/manage-client.module#ManageClientModule",
                canActivate: [AuthAdminGuardService]
            }, {
                path: "manage-courier",
                loadChildren: "./pages/manage-courier/manage-courier.module#ManageCourierModule",
                canActivate: [AuthAdminGuardService]
            }, {
                path: "manage-partner",
                loadChildren: "./pages/manage-partner/manage-partner.module#ManagepartnerModule",
                canActivate: [AuthAdminGuardService]
            }, {
                path: "setting",
                component: SettingComponent,
                canActivate: [AuthAdminGuardService],
                canDeactivate: [PendingChangesGuard]
            }
        ]
    },
    {
        path: "client-mob",
        loadChildren: "./pages/client-mob/client-mob.module#ClientMobModule",
        canActivate:[AuthClientGuardService]
    },
    { path: "login", component: LoginComponent },
    { path: "forgetpwd", component: ForgetPwdComponent },
    { path: "**", pathMatch: "full", redirectTo: "" }
];