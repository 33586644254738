import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from "../../../services/user.service";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    userEmail = '';
    userPassword = '';
    errorLoginMsg: string;
    btnDisable = false;
    screenHeight;
    screenWidth;
    @HostListener('window:resize', ['$event'])
    onResize(event?) {
        this.screenHeight = window.innerHeight;
        this.screenWidth = window.innerWidth;
    }

    constructor(
        private router: Router,
        private userService: UserService,
    ) {
        this.onResize();

    }

    ngOnInit() {

    }

    goForgetPassword() {
        this.router.navigate(['forgetpwd']);
    }

    doLogin() {
        this.userService.login(this.userEmail, this.userPassword).subscribe(
            data => {
                this.errorLoginMsg = '';
                this.userService.getCurrentUser().subscribe(
                    data => {
                        let isAdmin = this.userService.isAdmin();
                        let isDispatch = this.userService.isDispatch();
                        let isClient = this.userService.isClient();
                        if (isAdmin || isDispatch) {
                            this.router.navigateByUrl('/');
                        } else if (isClient) {
                            if (this.screenWidth <= 650) {
                                this.router.navigate(['client-mob']);
                            } else {
                                this.router.navigateByUrl('/');
                            }
                        } else {
                            this.errorLoginMsg = 'Invalid user name or password.';
                            setTimeout(() => {
                                this.errorLoginMsg = '';
                            }, 15000);
                        }
                    },
                    err => {
                    }
                );
            },
            err => {
                this.errorLoginMsg = 'Invalid user name or password.';
                setTimeout(() => {
                    this.errorLoginMsg = '';
                }, 15000);
            }
        );
    }
}
