export const URL_CONSTANT = Object.freeze({
    BASE_URL: 'https://api.snap.zigzagcourier.com/api/',
    // BASE_URL: 'http://backend.snap.moregoldtech.com/api/',
    //BASE_URL: 'http://192.168.0.192:8000/api/',
    LOGIN: 'o/token/',
    SIGN_UP: 'users/sign-up/',
    GET_RESET: 'send-reset-to-user/',
    RESET_PASSWORD: 'reset-password/',
    CURRENT_USER: 'users/current-user/',
    ORDERS: 'orders/',
    ZONES: 'zones/',
    COST_CENTER: 'cost-centers/',
    CLIENTS: 'clients/',
    USERS: 'users/',
    ORDER_LOGS: 'order-logs/',
    ADDRESSES: 'addresses/',
    FETCH_ADDRESS: 'fetch-pickup-address/',
    CONFIGS: 'configs/',
    ACTION_BATCH: 'batch/',
    ACTIVE_ZONE_USER: 'zones-active-user/',
    FINISH_ORDER: 'fetch-finish-order/',
    FILE: 'order-files/',
    REORDER: 'reorder/',
    GPS_POSITION: 'coordinates/',
    LOGS_URL: 'api-logs/',
    PARTNER_LOGS_COUNT:'api-logs/apilogs-fetch/?partner_id=',
    PARTNER_CREATE_COUNTS: 'orders/month-fetch/?partner_id='
});
