import { Component, OnInit, HostListener } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { ConfigService } from "../../services/config.service";
import { OrderService } from "../../services/order.service";
import { ConfirmmodalComponent } from '../modal/confirmmodal/confirmmodal.component';
import { CommucateService } from 'src/app/services/commucate.service';

@Component({
    selector: 'app-setting',
    templateUrl: './setting.component.html',
    styleUrls: ['./setting.component.css']
})
export class SettingComponent implements OnInit {
    orderTimes: any[] = [
        {
            title: "ASAP Pickup Span",
            description: "Time added to current time when an ASAP order is created. Default is 20 mins.",
            value: "20",
            key: "PICK UP SPAN",
            changed: false
        }, {
            title: "Delivery time",
            description: "Variable used to calculate the due time (Pickup time + Delivery span = Due time). Default is 30 mins.",
            value: "30",
            key: "DELIVERY SPAN",
            changed: false
        }, {
            title: "Burnt time",
            description: "Number of minutes before due time order is considered burnt. Default is 10 mins.",
            value: "10",
            key: "BURN TIME",
            changed: false
        }, {
            title: "Pre Burnt time",
            description: "Number of minutes before due time order is considered pre-burnt. Default is 20 mins.",
            value: "10",
            key: "PRE BURN TIME",
            changed: false
        }, {
            title: "Current orders time window",
            description: "Number of hours before pickup time is used to designate an order as 'current.' Current orders appear on the order dashboard.",
            value: "3",
            key: "TIME WINDOW",
            changed: false
        }
    ]
    constructor(
        public toastr: ToastrService,
        private configService: ConfigService,
        private orderService: OrderService,
        private commucateService: CommucateService,
        public modalService: NgbModal
    ) { }
    toastr_flag: boolean;
    ngOnInit() {
        this.initOrderTime();
        this.initTiers();
        this.initZones();
        this.initNotificationsData();
    }
    // Order Times
    public ordertimesData: any[] = [];
    async initOrderTime() {
        await this.configService.getConfigs("ORDER TIME").subscribe(
            data => {
                this.ordertimesData = data.results;
                data.results.forEach(ele => {
                    this.orderTimes.forEach(item => {
                        if (ele.name == item.key) {
                            item.value = ele.value;
                            item['copy_value'] = ele.value;
                            item.id = ele.id;
                        }
                    });
                });
            },
            err => {
                this.toastr.error(err.message);
            }
        );
    }
    updateOrderTime(item) {
        this.toastr_flag = true;
        let id = item.id;
        let value = item.value;
        if (value >= 1 && value <= 60) {
            this.configService.updateOrderTime(id, value).subscribe(
                data => {
                    if (this.toastr_flag) {
                        this.toastr.success(item.title + " updated", "", { timeOut: 2000 });
                    };
                    this.toastr_flag = false;
                    item.value = data.value;
                    item.copy_value = data.value;
                    item.changed = false;
                },
                err => {
                    this.toastr_flag = false;
                    this.toastr.error(err.message);
                }
            );
        } else {
            this.toastr.error("Please enter a valid number between 1-60");
        }

    }
    // Order size tiers
    public orderTiersData: any[] = [];
    orderTiersChanged = false;
    addTiers() {
        this.orderTiersData.push(
            {
                "operation": "NEW",
                "group": "ORDER SIZE",
                "name": "ORDER SIZE",
                "value": ""
            }
        )
        this.isChangeForAttr(this.orderTiersData, 'tier');
    }
    removeTiers(i) {
        if (this.orderTiersData[i].operation == "NEW") {
            this.orderTiersData.splice(i, 1);
        } else {
            this.orderTiersData[i].operation = "DELETE";
        };
        this.isChangeForAttr(this.orderTiersData, 'tier');
    }
    saveTiers() {
        if (!this.orderTiersChanged) {
            return;
        };
        var filterRule=/[a-zA-Z]/g;


        let flag = this.orderTiersData.filter(v => !(v.value.trim()) && v.operation != 'DELETE').length;
        let otherflag=this.orderTiersData.filter(v => v.value.match(filterRule)).length;

        if (flag > 0) {
            this.toastr.error('Please fill in the required fields before saving!')
            return;
        }
        if (otherflag > 0) {
            this.toastr.error("Please fill in nubers or symbols '+' , '-' , '$', ','")
            return;
        }
        this.orderTiersChanged = false;
        this.configService.saveTiersBatch(this.orderTiersData).subscribe(
            data => {
                this.toastr.success("Order size tiers updated", "", { timeOut: 2000 });
                this.initTiers();
            },
            err => {
                this.orderTiersChanged = true;
                this.toastr.error(err.message);
            }
        );
    }
    openConfirm(i) {
        let contect = "The tier will be deleted as an option for new orders";
        let conformModalRef = this.modalService.open(ConfirmmodalComponent, { size: "sm", backdropClass: "stacked-modals", "centered": true });
        conformModalRef.componentInstance.actionData = contect;
        conformModalRef.componentInstance.actionType = 'Delete';
        conformModalRef.result.then((result) => {
            this.removeTiers(i);
        }, (reason) => {

        });
    }
    init_tier_str: string = ``;
    async initTiers() {
        await this.configService.getConfigs("ORDER SIZE").subscribe(
            data => {
                this.orderTiersData = [];
                this.orderTiersData = data.results;
                this.orderTiersData.forEach(ele => {
                    ele.operation = "UPDATE";
                });
                this.init_tier_str = JSON.stringify(this.orderTiersData);
                this.isChangeForAttr(this.orderTiersData, 'tier');
            },
            err => {
                this.toastr.error(err.message);
            }
        );
    }

    // Client zones
    public zonesConfigData: any[] = [];
    orderZonesChanged = false;
    addZones() {
        this.zonesConfigData.push(
            {
                "operation": "NEW",
                "name": ""
            }
        )
        this.isChangeForAttr(this.zonesConfigData, 'zone');
    }
    removeZones(i) {
        if (this.zonesConfigData[i].operation == "NEW") {
            this.zonesConfigData.splice(i, 1);
        } else {
            this.zonesConfigData[i].operation = "DELETE";
        }
        this.isChangeForAttr(this.zonesConfigData, 'zone');
    }
    saveZones() {
        if (!this.orderZonesChanged) {
            return;
        };
        let flag = this.zonesConfigData.filter(v => !(v.name.trim()) && v.operation != 'DELETE').length;
        if (flag > 0) {
            this.toastr.error('Please fill in the required fields before saving!')
            return;
        };
        this.orderZonesChanged = false;
        this.configService.saveZonesBatch(this.zonesConfigData).subscribe(
            data => {
                this.toastr.success("Client zones updated", "", { timeOut: 2000 })
                this.initZones();
            },
            err => {
                this.toastr.error(err.message);
                this.orderZonesChanged = true;
            }
        );
    }
    init_zones_str: string = ``;
    async initZones() {
        await this.orderService.getZones().subscribe(
            data => {
                this.commucateService.zonesMessage(data.results);
                this.zonesConfigData = [];
                data.results.forEach(ele => {
                    this.zonesConfigData.push({
                        count: ele.cost_center_count,
                        name: ele.name,
                        id: ele.id,
                        operation: "UPDATE"
                    });
                });
                this.init_zones_str = JSON.stringify(this.zonesConfigData);
                this.isChangeForAttr(this.zonesConfigData, 'zone');
            },
            err => {
                this.toastr.error(err.message);
            }
        );
    }

    // Notifications
    public notificationsData: any[] = [];
    notificationsChanged = false;
    notifications: any[] = [
        {
            title: "New order in courier’s zone",
            description: "Content of courier notification of new order.",
            value: "notifications",
            key: "CREATED CONTENT",
            changed: false
        }, {
            title: "Order dispatched to courier",
            description: "Content of notification to courier upon having an order dispatched to them.",
            value: "notifications",
            key: "DISPATCHED CONTENT",
            changed: false
        }, {
            title: "Changes to courier’s order",
            description: "Content of notification to courier if one of their order is changed.",
            value: "notifications",
            key: "CHANGES CONTENT",
            changed: false
        }, {
            title: "Welcome new clients",
            description: "Message to new clients that are added to the system.",
            value: "notifications",
            key: "WELCOME_CLIENT",
            changed: false
        }, {
            title: "Welcome new couriers",
            description: "Message to new couriers that are added to the system.",
            value: "notifications",
            key: "WELCOME_COURIER",
            changed: false
        }
    ]

    async initNotificationsData() {
        await this.configService.getConfigs("NOTIFICATION").subscribe(
            data => {
                this.notificationsData = data.results;
                data.results.forEach(ele => {
                    this.notifications.forEach(item => {
                        if (ele.name == item.key) {
                            item.value = ele.value;
                            item['copy_value'] = ele.value;
                            item.id = ele.id;
                        }
                    });
                });
            },
            err => {
                this.toastr.error(err.message);;
            }
        );
    }

    updateNotification(item) {
        this.toastr_flag = true;
        let id = item.id;
        let value = item.value.trim() ? item.value.trim() : null;
        this.configService.updateOrderTime(id, value).subscribe(
            data => {
                if (this.toastr_flag) {
                    this.toastr.success((item.title + " updated"), "", { timeOut: 2000 });
                };
                this.toastr_flag = false;
                item.value = data.value;
                item['copy_value'] = data.value;
                item.changed = false;
            },
            err => {
                this.toastr_flag = false;
                this.toastr.error(err.message);
            }
        );
    };
    isChange(item) {
        item.changed = item.value == item.copy_value ? false : true;
    };
    isChangeForAttr(items, type) {
        let temp_str = JSON.stringify(items);
        if (type == 'tier') {
            this.orderTiersChanged = this.init_tier_str == temp_str ? false : true;
        } else {
            this.orderZonesChanged = this.init_zones_str == temp_str ? false : true;
        }
    }
    @HostListener('window:beforeunload')
    canDeactivate(): Observable<any> | any {
        let new_array = this.orderTimes.concat(this.notifications);
        let flag = new_array.filter(v => !!v.changed).length;
        let temp_array = [flag, this.orderZonesChanged, this.orderTiersChanged];
        return temp_array.filter(v => !!v).length > 0 ? false : true;
    };
}
